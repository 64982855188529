<template>
  <div id="PendingTitles">
    <br />
    <section class="hero is-light" id="Home">
      <div class="hero-body">
        <h2 class="title">Title Printing</h2>
        <div>
          <label for="invoiceNum" style="display: inline-block">Invoice:</label>
          <input
            id="invoiceNum"
            style="width: 15%; display: inline-block"
            v-model="invoiceNum"
            type="text"
          />
          <button
            style="font-size: 12px; padding: 0.5rem 1rem 0.5rem 1rem"
            @click="search"
          >
            Search
          </button>
        </div>
      </div>
    </section>
    <div style="padding: 10px">
      <button @click="$router.go(-1)">Back</button>
    </div>
  </div>
</template>
<script>
export default {
  name: "PendingTitles",
  data() {
    return {
      invoiceNum: ""
    };
  },
  methods: {
    search() {}
  },
  mounted() {}
};
</script>
<style scoped></style>
